<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <b-form-group
              id="input-group-name"
              label="Название"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.Name"
                placeholder="Название"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-is-active"
              label="Активен"
              label-for="input-is-active"
            >
              <switch-checkbox v-model="f.IsActive" label="Активен" />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from './FiltersMixin';
import SwitchCheckbox from '../SwitchCheckbox.vue';

export default {
  components: { SwitchCheckbox },
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        Name: this.$route.query?.Name,
        IsActive: this.$route.query?.IsActive === 'true' || false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
