<template>
  <div class="auto-dealer-table">
    <v-data-table
      v-if="items !== null"
      :headers="headers"
      :items="localItems"
      item-key="name"
      :item-class="itemClass"
      class="elevation-1 row-links"
      disable-pagination
      hide-default-footer
      @sorted="saveOrder"
    >
      <template #item.Name="{ item }">
        {{ item.Name }}
      </template>

      <template #item.Notes="{ item }">
        {{ item.Notes }}
      </template>

      <template #item.IsActive="{ item }">
        <yes-no-badge :active="item.IsActive" />
      </template>

      <template #item.ID="{ item }">
        <router-link
          :to="{ name: urlName, params: { id: item.ID } }"
          title="Просмотр"
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Visible.svg"></inline-svg>
          </span>
        </router-link>

        <a
          href="#"
          title="Редактирование"
          class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          @click="clickEdit(item)"
        >
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
          </span>
        </a>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editedName }}</h5>
      </template>

      <publications-element-group-form
        v-model="editItem"
        ref="publicationsElementGroupForm"
      />
      <template #modal-footer="{ cancel }">
        <b-button
          :disabled="disabledButton"
          size="sm"
          variant="success"
          @click="saveForm"
        >
          Сохранить
        </b-button>
        <b-button
          :disabled="disabledButton"
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PublicationsElementGroupForm from '@/view/content/forms/publications/PublicationsElementGroupForm';
import { GET_ELEMENT_GROUP } from '@/core/services/store/publications.module';
import YesNoBadge from '@/view/content/YesNoBadge';

export default {
  components: { YesNoBadge, PublicationsElementGroupForm },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },

    filtered: {
      type: Boolean,
      deafult: () => false,
    },
  },

  data() {
    return {
      localItems: this.items,
      modalShow: false,
      editItem: null,
      disabledButton: false,
      headers: [
        { text: 'Название', value: 'Name', sortable: false },
        { text: 'Примечание', value: 'Notes', sortable: false },
        {
          text: ' Активен',
          value: 'IsActive',
          sortable: false,
        },
        { text: 'Действия', sortable: false, value: 'ID' },
      ],
    };
  },

  computed: {
    editedName() {
      return this.editItem?.Name;
    },
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.editItem = null;
    });
  },

  watch: {
    items(v) {
      if (v === null) {
        this.localItems = [];
      } else {
        this.localItems = v;
      }
    },
  },

  methods: {
    saveOrder(event) {
      const movedItem = this.localItems.splice(event.oldIndex, 1)[0];
      this.localItems.splice(event.newIndex, 0, movedItem);
    },

    itemClass() {
      return this.filtered ? 'filtered' : '';
    },

    async clickEdit(item) {
      this.editItem = await this.$store.dispatch(GET_ELEMENT_GROUP, item.ID);
      this.modalShow = true;
    },

    async saveForm() {
      this.disabledButton = true;
      const result = await this.$refs.publicationsElementGroupForm.submit();

      if (result) {
        this.modalShow = false;
        this.editItem = null;
      }

      this.$emit('saved');
      this.disabledButton = false;
    },
  },
};
</script>

<style lang="scss"></style>
