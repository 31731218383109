<template>
  <div class="create-block-buttons block-element-group">
    <b-button variant="success" @click="modalShow = true"> Создать </b-button>
    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Создание блока</h5>
      </template>

      <publications-element-group-form
        v-model="createItem"
        ref="publicationsCreateElementGroupForm"
      />

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="saveForm">
          Создать
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PublicationsElementGroupForm from '@/view/content/forms/publications/PublicationsElementGroupForm';

export default {
  components: {
    PublicationsElementGroupForm,
  },

  data() {
    return {
      modalShow: false,
      createItem: {
        BGHEXColor: null,
        Code: null,
        Name: '',
        Notes: '',
        IsActive: true,
      },
    };
  },

  methods: {
    async saveForm() {
      const result =
        await this.$refs?.publicationsCreateElementGroupForm?.submit();
      if (result) {
        this.modalShow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
