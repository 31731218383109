<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div class="form-subheader">Сведения</div>
          <b-form>
            <b-form-group
              id="input-code-group"
              label="Код"
              label-for="input-code"
            >
              <b-form-input
                id="input-code"
                v-model="form.Code"
                :state="validateState('Code')"
                placeholder="Код"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Название блока"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.Name"
                :state="validateState('Name')"
                placeholder="Название блока"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-5"
              label="Цвет фона"
              label-for="input-5"
            >
              <b-form-input
                id="input-5"
                type="color"
                v-model="form.BGHEXColor"
                placeholder="Цвет фона"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-6"
              label="Цвет текста"
              label-for="input-6"
            >
              <b-form-input
                id="input-6"
                type="color"
                v-model="form.TextHEXColor"
                placeholder="Цвет текста"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Примечание" id="input-group-7">
              <b-form-textarea
                id="input-notes"
                v-model="form.Notes"
                placeholder="Примечание"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group id="input-group-7">
              <switch-checkbox v-model="form.IsActive" label="Активен" />
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import SwitchCheckbox from '@/view/content/SwitchCheckbox';
import { SAVE_ELEMENT_GROUP } from '@/core/services/store/publications.module';

export default {
  components: { SwitchCheckbox },

  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
    };
  },

  computed: {
    isExists() {
      return Boolean(this?.form?.ID);
    },
  },

  validations: {
    form: {
      Name: {
        required,
      },
      Code: {
        required,
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  mounted() {
    if (this.form.BGHEXColor !== null) {
      this.form.BGHEXColor = '#' + this.form.BGHEXColor;
    }
    if (this.form.TextHEXColor !== null) {
      this.form.TextHEXColor = '#' + this.form.TextHEXColor;
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    prepareForm() {
      if (this.form.BGHEXColor !== null) {
        this.form.BGHEXColor = this.form.BGHEXColor.replace('#', '');
      }
      if (this.form.TextHEXColor !== null) {
        this.form.TextHEXColor = this.form.TextHEXColor.replace('#', '');
      }
    },

    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      } else {
        this.prepareForm();
        const result = await this.$store.dispatch(
          SAVE_ELEMENT_GROUP,
          this.form
        );
        if (result.error === null) {
          Swal.fire({
            title: '',
            text: this.isExists ? 'Сохранено!' : 'Создано!',
            icon: 'success',
            heightAuto: false,
          });
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
