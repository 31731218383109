<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <v-expansion-panels class="mb-4">
            <v-expansion-panel>
              <v-expansion-panel-header> Фильтры </v-expansion-panel-header>
              <v-expansion-panel-content>
                <publications-list-filter />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <custom-header title="Публикации">
            <create-element-group-button />
          </custom-header>

          <publications-list
            :items="filterItems"
            :filtered="filtered"
            url-name="publications-detail"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SET_BREADCRUMB,
  SET_BREADCRUMB_SHOW_BACK,
} from '@/core/services/store/breadcrumbs.module';
import { GET_ITEMS_PUBLICATIONS } from '@/core/services/store/publications.module';
import { mapState } from 'vuex';
import PublicationsList from '@/view/content/lists/PublicationsList.vue';
import PublicationsListFilter from '@/view/content/filters/PublicationsListFilter.vue';
import FilteredRouteItemsMixin from '@/view/content/filters/FilteredRouteItemsMixin';
import CreateElementGroupButton from '@/view/content/widgets/publications/CreateElementGroupButton';

export default {
  components: {
    PublicationsList,
    PublicationsListFilter,
    CreateElementGroupButton,
  },

  mixins: [FilteredRouteItemsMixin],

  computed: {
    ...mapState({
      items: (state) => state.publications.publicationsItems,
    }),
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Публикации' }]);
    this.$store.commit(SET_BREADCRUMB_SHOW_BACK, false);
    await this.$store.dispatch(GET_ITEMS_PUBLICATIONS, this.$route.query);
  },
};
</script>
